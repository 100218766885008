<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Llamado Actual</h3>
          <table class="table table-BORDERED">
            <thead>
              <tr>
                <th>
                  Contraseña
                </th>
                <th>
                  Nombre
                </th>
                <th>
                  Consultorio
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>123</td>
                <td>Joice Paim</td>
                <td>Clinico General</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div class="col-md-11 mt-2">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Próximos Llamados</h3>
          <table class="table table-BORDERED">
            <thead>
              <tr>
                <th>
                  Contraseña
                </th>
                <th>
                  Nombre
                </th>
                <th>
                  Consultorio
                </th>
                <th>

                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>123</td>
                <td>Joice Paim</td>
                <td>Clinico General</td>
                <td>
                  <button class="btn btn-info">
                    <i class="fa fa-search search-icon m-0 p-0"></i>
                  </button>
          
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      removido: [],
      currentPage: 1,
      perPage: 10,
      filtro: {
        data_inicio: null,
        data_fim: null,
        paciente: null,
      },
      // texto:
    };
  },
  // watch: {
  //   filtro(){
  //     console.log(3)
  //   }
  // },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Fila Atendimientos" }]);
  },
  created() {
    this.listar_atendimentos();
  },
  computed: {
    lista_atendimentos() {
      return this.$store.state.atendimento.lista_atendimentos;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.atendimento.mensagem_alert;
    },
  },
  methods: {
    async listar_atendimentos() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("atendimento/listar_atendimentos")
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("configEmpresa/atualizar", "");
        });
    },
    atualizar(value) {
      this.$router.push({ name: "createAtendimento" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async pesquisar() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch(
        "atendimento/pesquisar_atendimentos",
        this.filtro
      );
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async confirm(value, text) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta accion ` + text,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (
              text ==
              "ELIMINARA el atendimiento del sistema. Este no contabilizara en los relatorios, tampoco en el historico del paciente"
            )
              this.bloquear(value);
            if (text == "FINALIZARA el atendimiento del sistema")
              this.finalizar(value);
          }
        },
      });
    },
    async bloquear(value) {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("atendimento/bloquear_atendimento", value)
        .then(this.removido.push(value));
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      this.fireAlert(this.mensagem_alert);
    },
    async finalizar(value) {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("atendimento/finalizar_atendimento", value);
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      this.listar_atendimentos();
      this.fireAlert(this.mensagem_alert);
    },
    async trocar_pagina(id) {
      this.$router.push({
        name: "createOcorrencia",
        params: { atendimento_id: id },
      });
    },
  },
};
</script>
  
<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>